import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        suggest(ctx, url) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/suggest', {
                        url: url,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}