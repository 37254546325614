export default [
  {
    path: '/vote/albums/ru',
    name: 'vote-albums-ru',
    component: () => import('@/views/vote/albums/AlbumsRu.vue'),
    meta: {
      resource: 'album',
      action: 'vote',
    },
  },
  {
    path: '/vote/albums/en',
    name: 'vote-albums-en',
    component: () => import('@/views/vote/albums/AlbumsEn.vue'),
    meta: {
      resource: 'album',
      action: 'vote',
    },
  },
  {
    path: '/vote/songs/ru',
    name: 'vote-songs-ru',
    component: () => import('@/views/vote/songs/SongsRu.vue'),
    meta: {
      resource: 'song',
      action: 'vote',
    },
  },
  {
    path: '/vote/songs/en',
    name: 'vote-songs-en',
    component: () => import('@/views/vote/songs/SongsEn.vue'),
    meta: {
      resource: 'song',
      action: 'vote',
    },
  },
  {
    path: '/vote/videos/ru',
    name: 'vote-videos-ru',
    component: () => import('@/views/vote/videos/VideosRu.vue'),
    meta: {
      resource: 'video',
      action: 'vote',
    },
  },
  {
    path: '/vote/videos/en',
    name: 'vote-videos-en',
    component: () => import('@/views/vote/videos/VideosEn.vue'),
    meta: {
      resource: 'video',
      action: 'vote',
    },
  },
  {
    path: '/vote/year/ru',
    name: 'year-baskets-ru',
    component: () => import('@/views/year/baskets/BasketRu.vue'),
    meta: {
      resource: 'song', //todo: rename to basket
      action: 'vote',
    },
  },
  {
    path: '/vote/year/en',
    name: 'year-baskets-en',
    component: () => import('@/views/year/baskets/BasketEn.vue'),
    meta: {
      resource: 'song',
      action: 'vote',
    },
  },
]
