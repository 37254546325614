export default [
  {
    path: '/results/albums/ru',
    name: 'results-albums-ru',
    component: () => import('@/views/results/albums/AlbumsRu.vue'),
    meta: {
      resource: 'album',
      action: 'results',
    },
  },
  {
    path: '/results/albums/en',
    name: 'results-albums-en',
    component: () => import('@/views/results/albums/AlbumsEn.vue'),
    meta: {
      resource: 'album',
      action: 'results',
    },
  },
  {
    path: '/results/songs/ru',
    name: 'results-songs-ru',
    component: () => import('@/views/results/songs/SongsRu.vue'),
    meta: {
      resource: 'song',
      action: 'results',
    },
  },
  {
    path: '/results/songs/en',
    name: 'results-songs-en',
    component: () => import('@/views/results/songs/SongsEn.vue'),
    meta: {
      resource: 'song',
      action: 'results',
    },
  },
  {
    path: '/results/videos/ru',
    name: 'results-videos-ru',
    component: () => import('@/views/results/videos/VideosRu.vue'),
    meta: {
      resource: 'video',
      action: 'results',
    },
  },
  {
    path: '/results/videos/en',
    name: 'results-videos-en',
    component: () => import('@/views/results/videos/VideosEn.vue'),
    meta: {
      resource: 'video',
      action: 'results',
    },
  },
  {
    path: '/results/users/progress',
    name: 'users-progress',
    component: () => import('@/views/results/users/UsersProgress.vue'),
    meta: {
      resource: 'user',
      action: 'progress',
    },
  },
]
